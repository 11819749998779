body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.ui.basic.circular.icon.button {
  box-shadow: 0 0 0 0 transparent !important;
}

.ui.basic.circular.icon.button:hover,
.ui.basic.circular.icon.button:focus
{
  box-shadow: 0 0 0 0 transparent !important;
  background: lightgray !important;
}

.ui.progress .bar {
  min-width: 0;
}

.ui.tabular.menu .active.item {
  border-radius: 0 !important;
}

.mdl-button {
  border-radius: .28571429rem !important;
}
.App {
  text-align: center;
}

